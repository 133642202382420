import React, { useState } from "react";
import { projet } from "../Data";
import { Modal } from "flowbite-react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Projet() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null); // Suivi du projet sélectionné

  const handleProjectClick = (project) => {
    setSelectedProject(project); // Définit l'état du projet sélectionné
    setOpenModal(true); // Ouvre la modale
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProject(null); // Réinitialise l'état du projet sélectionné
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {projet.map((item) => (
          <div
            key={item.id} // Utilise une clé unique pour chaque projet
            onClick={() => handleProjectClick(item)}
            className="group relative"
          >
            <div class="m-3 relative overflow-hidden ">
              <img
                src={item.image}
                class="rounded-md  object-cover h-50 w-96 border-2 border-white  group-hover:scale-125 duration-300"
              />
              <div className=" rounded-md absolute  cursor-pointer -bottom-10 flex flex-col justify-center items-center h-full w-full bg-[#13171F]/50 opacity:0 border-2 border-white hover:rounded-md hover:border-[#17E7ED] group-hover:bottom-0 opacity-0  group-hover:opacity-100 transition-all duration-300">
                <h5 class="block mb-1 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-white">
                  {item.intituler}
                </h5>
                <p className="text-white font-thin">{item.resume}</p>
                <span className="font-medium text-[#17E7ED]">
                  En savoir plus
                </span>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {openModal &&
        selectedProject && ( // Ne rend la modale que si elle est ouverte et qu'un projet est sélectionné
          <Modal dismissible show={openModal} onClose={handleCloseModal}>
            <Modal.Header>{selectedProject.intituler}</Modal.Header>
            <Modal.Body>
              <div className="space-y-6">
                {selectedProject.intituler == "Introduction JT" ? (
                  <div className="flex flex-col items-center justify-center ">
                    <ReactPlayer
                      url="/assets/videos/intro-eurotech.mp4"
                      width={540}
                      controls
                    />
                  </div>
                ) : (
                  <img src={selectedProject.image} />
                )}

                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  {selectedProject.description}
                </p>
                <p>Outil :</p>
                <ul className="flex flex-row">
                  {selectedProject.outil.map((item) => (
                    <li
                      className="p-2"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={item.nom}
                    >
                      <ReactTooltip type="success" id="my-tooltip" />
                      <img className=" w-10 h-10" src={item.image} />
                    </li>
                  ))}
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {selectedProject.detail == "" ? (
                ""
              ) : (
                <a
                  href={selectedProject.detail}
                  target="_blank"
                  class="bg-transparent border text-text-slate-900  bg-border hover:bg-slate-900 hover:text-white font-bold py-2 px-3 rounded-full"
                >
                  détail
                </a>
              )}
              {selectedProject.gitlab == "" ? (
                ""
              ) : (
                <a href={selectedProject.gitlab} target="_blank" className="">
                  <img
                    className="w-10 h-10"
                    src={"/assets/images/gitlab.png"}
                  />
                </a>
              )}
              {selectedProject.github == "" ? (
                ""
              ) : (
                <a href={selectedProject.github} target="_blank" className="">
                  <img
                    className="w-10 h-10"
                    src={"/assets/images/github.png"}
                  />
                </a>
              )}
            </Modal.Footer>
          </Modal>
        )}
    </>
  );
}
